.news_card_wrapper {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  width: 100%;
  gap: 20px;
  margin-top: 5rem;
  padding: 0px 80px;
  width: 100%;
}

.news_card_wrapper_phone {
  grid-template-columns: repeat(1, 1fr);
}

.news-news-card-wrapper {
  margin: 0px;
  padding: 0px;
  cursor: pointer;
  width: 100%;
}

.news_filter_wrapper_phone {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  flex-direction: column;
  align-items: flex-start;
  gap: 20px 0px;
}

.news_filter_wrapper {
  display: flex;
  width: 100%;
  justify-content: flex-start;
  flex-direction: row;
  align-items: center;
  gap: 0px 0px;
  padding: 0px 80px;
}

.news_select_filter {
  width: 30%;
  height: 40px;
  border: none;
  outline: none;
  font-weight: 400;
  font-size: 16px;
  line-height: 40px;
  margin-left: 1rem;
}

.news-detail-wrapper {
  width: 80%;
  display: flex;
  flex-direction: column;
  padding: 150px 80px;
  gap: 30px;
}

/* .news-detail-wrapper h1 {
  margin-bottom: 2rem;
} */

.news-details-date-wrapper {
  display: flex;
  gap: 40px;
}

.related-news-wrapper {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.related-news-wrapper h4 {
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
}

.related-news-wrapper img {
  width: 100%;
}

.news-details-cards-wrapper {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: 30px;
}

.news-details-cards {
  display: flex;
  align-items: center;
  gap: 20px;
  width: 50%;
}

.news-details-cards img {
  width: 130px;
  height: 130px;
}

.news-details-content-wrapper {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.news-details-content-wrapper h4 {
  font-weight: 700;
  font-size: 18px;
  line-height: 29px;
}

.news-details-content-date {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.news-details-content-date p,
.news-details-date-wrapper p {
  font-weight: 400;
  font-size: 14px;
  line-height: 40px;
  color: #46c35f;
}

@media (max-width: 900px) {
  .news-detail-wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
    padding: 150px 16px;
    gap: 30px;
  }

  .news-details-cards {
    width: 100%;
  }

  .news-details-cards-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    gap: 30px;
  }

  .news-details-content-date p,
  .news-details-date-wrapper p {
    font-size: 12px;
  }
}

@media (max-width: 400px) {
  /* .news-details-content-date {
    flex-direction: column;
  } */

  .news-details-content-date p,
  .news-details-date-wrapper p {
    font-size: 8px;
  }
}
