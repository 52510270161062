.contact_section_wrapper {
  padding: 5rem 1rem;
}

.contact_info_section_address_wrapper {
  display: flex;
  flex-direction: column;
}

.contact_info_section_address_wrapper h4 {
  display: flex;
  align-items: center;
  font-weight: 700;
  font-size: 18px;
  line-height: 40px;
  margin: 0px;
  color: #2e2e2e;
}

.contact_info_section_address_wrapper p {
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  color: #2e2e2e;
}

.contct_address_wrapper {
  margin: 0px 0px 18px;
}

.contct_address_wrapper img {
  margin: 0px 9px 0px 0px;
}

.contact_form_wrapper {
  background: #ffffff;
  border-radius: 2px;
  padding: 40px 16px;
  box-shadow: 0px -1px 27px rgba(0, 0, 0, 0.05),
    0px -0.876509px 3.38082px rgba(0, 0, 0, 0.025);
}

.contact_form_wrapper h4 {
  font-weight: 700;
  font-size: 16px;
  line-height: 28px;
  color: #2e2e2e;
  margin: 0px 0px 24px;
}

.contact_textfield {
  background: #f7f9fa;
  border-radius: 2px;
  width: 100%;
  padding: 15px 23px !important;
  outline: none;
  border: none;
}

.contact_form {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 24px;
}

.contact_icon_color {
  color: #46c35f;
}

.map-picture {
  display: flex;
  width: 100%;
  padding: 50px 80px 150px 80px;
}

@media (min-width: 1024px) {
  .contact_section_wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 80px 192px;
  }
  .contact_form {
    grid-template-columns: repeat(2, 1fr);
  }

  .contact_textfield_message {
    grid-column-start: 1;
    grid-column-end: 3;
  }

  .contact_btn {
    grid-column-start: 1;
    grid-column-end: 3;
  }
}

@media (max-width: 900px) {
  .map-picture {
    display: flex;
    width: 100%;
    padding: 20px 16px 150px 16px;
  }
}
