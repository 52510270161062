.nti_prog_card_wrapper {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 20px;
  width: 100%;
  margin: 30px 0px;
}

.nti_prog_cards_card {
  padding: 28px 20px;
  background: #ffffff;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
  border-left: 5px solid #46c35f;
}

.nti_prog_cards_card:hover {
  border-right: 1px solid #46c35f;
  border-top: 1px solid #46c35f;
  border-bottom: 1px solid #46c35f;
  box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
  transition: ease-in-out 0.5s;
}

.nti_programme_wrapper {
  padding: 2rem 1rem;
  margin: 45px 0px 200px;
}

.pgde_dropdown_lists {
  cursor: pointer;
}

.pgde_dropdown_lists:hover {
  color: #00c853;
}

.pgde_content_wrapper,
.bed_wrapper {
  padding: 3rem 0rem;
}

.pgde_wrapper h4,
.nce_wrapper_content h4,
.bed_wrapper_content h4,
.adee_wrapper_content h4 {
  margin: 31px 0px;
}

.pgde_wrapper_content h4,
.nce_wrapper_content h4 {
  margin-bottom: 10px;
}

.pgde_wrapper_content p {
  font-size: 16px;
}

.pgde_content_wrapper ol,
.pgde_wrapper ol,
.bed_wrapper ol,
.bed_wrapper ul,
.nce_general_wrapper ol,
.ade_general_wrapper ol,
.general_adee_wrapper ol,
.general_adee_wrapper ul,
.pttp_general_wrapper ol {
  padding: 1rem;
}

.pgde_content_wrapper li,
.pgde_wrapper li,
.nce_general_wrapper li,
.ade_general_wrapper li,
.general_adee_wrapper li,
.pttp_general_wrapper li {
  list-style-type: decimal;
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  color: #2e2e2e;
  margin-bottom: 10px;
  text-transform: capitalize;
}

.general_adee_wrapper ul li {
  list-style-type: lower-roman;
}

.pgde_dropdown_lists:hover {
  color: #00c853;
}

.bed_wrapper_content ul {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px 20px;
}

.capacityBuilding_wrapper {
  padding: 1rem;
  margin: 20px 0px 200px;
}

.capacityBuilding_wrapper p {
  margin-bottom: 1rem;
  line-height: 40px;
}

.requirements_prog_card_wrapper {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 30px;
  width: 100%;
  margin: 30px 0px;
}

.requirements_prog_cards_card {
  width: 100%;
  padding: 28px 0px;
  background: #ffffff;
  box-shadow: 2px 5px 6px rgba(0, 0, 0, 0.08);
  border-left: 4px solid #46c35f;
}

.requirements_cards_title {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 1rem;
  width: 100%;
}

.payment_pgde_wrapper {
  margin-bottom: 200px;
}

.payment_pgde_wrapper div {
  margin-top: 2rem;
}

.payment_pgde_wrapper ul {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.payment_pgde_wrapper li,
.bed_wrapper li,
.requirements_additional_entry_wrapper li,
.capacityBuilding_wrapper li {
  width: 100%;
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  color: #2e2e2e;
  margin-bottom: 20px;
}

.payment_pgde_wrapper li:before,
.bed_wrapper li:before,
.requirements_additional_entry_wrapper li:before,
.capacityBuilding_wrapper li:before {
  content: "\2022";
  font-weight: bolder;
  display: inline-block;
  width: 1em;
  /* margin-right: 1rem; */
  color: #46c35f;
}

.parent-div {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 20px 20px;
  width: 100%;
  margin-top: 40px;
}
.app-div,
.course-div {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  box-shadow: 2px 5px 6px rgba(0, 0, 0, 0.08);
}

.upper-div {
  flex: 1;
  background: #f6f6f6;
  font-weight: 400;
  font-size: 16px;
  line-height: 40px;
  padding: 5px 0px;
  text-align: center;
}

.lower-div {
  display: flex;
  justify-content: center;
  flex-direction: column;
  flex: 4;
  background: #fff;
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  padding: 25px 0px;
  text-align: center;
}

.course-lower-div {
  flex: 4;
  background: #fff;
  padding: 22px 20px;
}

@media (min-width: 1024px) {
  .nti_prog_card_wrapper {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px;
    width: 100%;
    margin: 30px 0px;
  }
  .requirements_prog_cards_card {
    display: flex;
    padding: 0px 0px;
  }

  .requirements_card_years {
    align-items: center;
    flex-direction: column;
    justify-content: center;
    width: 159px;
    height: 118px;
  }

  .requirements_cards_title {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    height: 100%;
  }
}

@media (max-width: 900px) {
  .parent-div {
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    gap: 20px 20px;
    width: 100%;
    margin-top: 40px;
  }
}
