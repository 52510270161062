* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: "Lato", sans-serif;
}

li {
  list-style-type: none;
}

.contact div {
  margin-top: 1rem;
}

.contact div span img {
  margin-right: 0.5rem;
}

.contact div span {
  display: block;
  font-weight: 600;
}

p {
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
}

a {
  text-decoration: none;
  color: #00c853;
}
h4 {
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  margin-bottom: 26px;
}
h3 {
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  margin-bottom: 26px;
}

details > summary {
  list-style-type: none;
  display: flex;
  align-items: center;
  border-top: 1px solid #eaeff3;
  padding-top: 1.5rem;
  padding-bottom: 0.5rem;
  text-transform: uppercase;
}

details > summary::-webkit-details-marker {
  display: none;
}

/* details > summary::before {
  content: url(./assets/icons/AccordionRight.svg);
  padding-top: 0.3rem;
  padding-right: 3rem;
} */

details > summary::before {
  content: url(./assets/icon/AccordionRight.svg);
  padding-top: 0.3rem;
  padding-right: 1rem;
}

details[open] > summary::before {
  content: url(./assets/icon/AccordionDown.svg);
  padding-top: 0.5rem;
  padding-right: 3rem;
}

details[open] {
  background: #f5fffe;
  border-top: 1px solid #eaeff3;
}

details {
  border-radius: 0.2rem;
  padding: 0.5rem 1rem;
  width: 100%;
}

details[open] > summary {
  margin-bottom: 0.5rem;
  padding-right: 3.5rem;
  transition: color ease 2s, transform ease 5s, margin ease 0.2s;
  border-top: none;
}

/* SCROLLBAR FEATURE */
/* width */
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px rgba(128, 128, 128, 0.575);
  border-radius: 5px;
}
/* Handle */
::-webkit-scrollbar-thumb {
  background: #46c35f;
  border-radius: 2px;
  /*   #2e9242*/
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgba(128, 128, 128, 0.863);
}

/* LOADER FEATURE */
.loader {
  /* background-color: #fff; */
  /* position: fixed; */
  width: 100%;
  height: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  left: 0;
  z-index: 999;
  right: 0;
  top: 0;
  bottom: 0;
}
.loader img {
  -webkit-animation: fadeinout 2s infinite forwards;
  animation: fadeinout 2s infinite forwards;
  /* width: 20%; */
  border: 2px solid #fff;
}
.loader .MuiLinearProgress-indeterminate {
  width: 20%;
  margin-top: 1rem;
}

.nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 10%;
}

.nav_scroll {
  position: fixed;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: rgba(255, 255, 255, 0.8);
  left: 0px;
  z-index: 500;
  box-shadow: 2px 5px 6px rgba(0, 0, 0, 0.08);
  padding: 5px 16px;
  top: 0px;
  right: 0px;
}

.navbar_list {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  height: 100%;
}

.dropdown {
  position: relative;
  display: inline-block;
  height: 2vh;
  color: #fff;
  cursor: pointer;
}

.dropdown-scroll {
  color: #000;
  cursor: pointer;
}

.dropdown-scroll-active {
  color: #46c35f;
  cursor: pointer;
}

.dropdown-active {
  color: #46c35f;
  cursor: pointer;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f1f1f1;
  width: 12rem;
  padding: 0.5rem;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  margin-top: 0.3rem;
  cursor: pointer;
  background: white;
  color: black;
  transition-delay: 10s;
}

.dropdown-content a {
  color: black;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  font-size: 14px;
}

.dropdown-content a:hover {
  background-color: #46c35f;
  color: #fff;
}

.dropdown:hover .dropdown-content {
  display: block;
  top: 100%;
  transition-delay: 10s;
}

.dropdown:hover .nav-link {
  background-color: doggerblue;
}

.navbar_list_items {
  font-weight: 400;
  font-size: 16px;
  line-height: 40px;
  color: #ffffff;
  cursor: pointer;
}

.side_bar_nav {
  display: none;
}

.side_bar_nav_active {
  position: fixed;
  display: flex;
  flex-direction: column;
  top: 0;
  right: 0;
  left: 0;
  width: 100%;
  background: #ffffff;
  height: 100vh;
  padding: 1rem;
}
.drawer
  .MuiPaper-root.MuiDrawer-paper.MuiDrawer-paperAnchorLeft.MuiPaper-elevation16 {
  width: 100%;
  height: 100%;
}

.MuiToolbar-root {
  height: 70px;
}

.menu_list_items_wrapper {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  flex-direction: column;
  width: 100%;
  height: 100%;
}

.sidebar_navbar_list {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 100%;
}

.sidebar_navbar_list_items {
  font-weight: 400;
  font-size: 16px;
  line-height: 40px;
  margin-bottom: 30px;
  color: #000000;
}

.side_bar_logo_wrapper {
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 0.5rem 1rem;
}

.arrow {
  color: #46c35f;
  margin-left: 10px;
}



.table_pagination {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
  margin: 90px 0px 0px 0px;
}

.table-row {
  background-color: #46C35F;
  color: #f5fffe;
  box-sizing: border-box; /* Prevents sizing issues */


}
.table-header {
  padding: 20px;
  text-align: left;
  box-sizing: border-box;
}
.table-data {
  padding: 20px;
}
.btn-table:hover{
  transition: all .3s ease-out;
  background-color: #46B846 !important;
  color: #f1f1f1 !important;
}
.btn-table-link:hover{
  color: #f1f1f1 !important;
}
.news_skeleton_wrapper {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  width: 100%;
  gap: 20px;
  margin-top: 5rem;
}

.news_skeleton_sub_heading_wrapper {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  width: 100%;
  gap: 20px;
}

.log-div {
  display: flex !important;
  padding: 0 !important;
}

.login-main {
  font-size: 25px !important;
  line-height: 40px !important;
  text-align: center;
  margin: 0 0 20px !important;
}

.log-sub {
  font-size: 16px !important;
  line-height: 27px !important;
  text-align: center;
}
.log-grid {
  padding: 50px 30px !important;
}

.center-loading {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.servicom_nti p {
  margin-top: -2rem;
}

.footer {
  background-image: url(./assets/images/footer_background.png);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  width: 100%;
  height: auto;
}

.footer_wrapper {
  background: linear-gradient(
    180deg,
    rgba(3, 26, 38, 0.63) 0%,
    rgba(3, 26, 38, 0.6) 5.18%,
    rgba(3, 26, 38, 0.9) 15.27%,
    #031a26 35.18%
  );
  width: 100%;
  height: 100%;
  padding: 70px 16px 48px;
  color: #fff;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
}

.footer_about,
.footer_news,
.footer_links,
.footer_newsletter {
  margin-bottom: 40px;
}

.footer_news_wrapper {
  display: flex;
  align-items: flex-start;
  margin-bottom: 16px;
}

.footer h4 {
  font-weight: 700;
  font-size: 18px;
  line-height: 40px;
}
.footer p {
  font-weight: 300;
  font-size: 14px;
  line-height: 27px;
  cursor: pointer;
}

.footer li {
  list-style-type: none;
  display: flex;
  align-items: center;
  margin-bottom: 16px;
  cursor: pointer;
}

.footer input {
  border: none;
  outline: none;
  background: linear-gradient(
    180deg,
    rgba(3, 26, 38, 0.63) 0%,
    rgba(3, 26, 38, 0.6) 5.18%,
    rgba(3, 26, 38, 0.9) 15.27%,
    #031a26 35.18%
  );
  margin-left: 18px;
  width: 100%;
  height: 100%;
  color: #fff;
}

.footer_input_wrapper {
  display: flex;
  align-items: center;
  border: 2px solid #ffffff;
  border-radius: 5px;
  padding: 10px;
  height: auto;
  margin: 30px 0px 20px;
}

.btn {
  width: 100%;
  height: 45px;
  border: none;
  outline: none;
  background: #46c35f;
  border-radius: 5px;
  color: #ffffff;
  font-weight: 700;
  font-size: 14px;
  line-height: 40px;
  text-align: center;
  cursor: pointer;
}

.footer_copyright {
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: #ffffff;
}

.footer_social_icons {
  margin-top: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.footer-social_icon_link:hover {
  transform: scale(1.2);
}

@media (min-width: 1024px) {
  .nav_scroll {
    padding: 5px 92px;
  }
  .footer_wrapper {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 40px;
    padding: 150px 92px;
  }

  .news_skeleton_wrapper {
    grid-template-columns: repeat(3, 1fr);
  }

  .footer_copyright {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    grid-column-start: 1;
    grid-column-end: 6;
    width: 100%;
    padding-top: 3rem;
    border-top: 1px solid rgba(248, 248, 254, 0.5);
  }

  .footer_social_icons {
    margin-top: 0px;
  }
}
