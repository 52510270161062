.home_page_sub_heading {
  font-style: normal;
  font-weight: 700;
  font-size: 12px;
  line-height: 40px;
  color: #ffffff;
  margin-bottom: 8px;
}

.home_page_heading {
  font-weight: 900;
  font-size: 40px;
  line-height: 60px;
  color: #ffffff;
  margin-bottom: 80px;
}

.home_btn {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  width: 70%;
  height: 50px;
  left: 0px;
  top: 0px;
  color: #ffffff;
  background: #46c35f;
  border: none;
  outline: none;
  border-radius: 4px;
  margin-bottom: 40px;
  cursor: pointer;
}

.home_header_wrapper {
  transition: 300ms;
}

/* .screen {
  background: url("../assets/images/about-history.jpg");
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  width: 100% !important;
  height: auto;
  transition: 3000ms;
  top: 0;
  right: 0;
} */

/* changing image soon */
/* .screen.nr2 {
  background: url("../assets/images/news-temp-picture3.jpg");
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
} */
/* .screen.nr3 {
  background: url("../assets/images/img1.png");
}
.screen.nr4 {
  background: url("../assets/images/img1.png");
} */

.header_wrapper {
  width: 100%;
  height: 100%;
  /* backdrop-filter: blur(1px); */
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0.6) 0%,
    rgba(0, 0, 0, 0.7) 14.06%,
    rgba(0, 0, 0, 0.8) 26.04%
  );

  padding: 13px 16px 30px 16px;
  z-index: 90;
}

.home_header_social_icon {
  color: #ffffff;
  width: 14px;
  margin-bottom: 23px;
}

.home_header_social_icon_divider {
  width: 0px;
  height: 174px;
  background: #ffffff;
  border: 1px solid #ffffff;
  margin-bottom: 23px;
}

.home_header_social_icon_wrapper {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.call_us_wrapper {
  display: flex;
  flex-direction: column;
}

.call_us {
  display: flex;
  align-items: center;
  margin-bottom: 40px;
}

.home_contact_info {
  color: #ffffff;
  margin-left: 20px;
}

.home_card_wrapper {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 20px 20px;
  padding: 1rem;
}

.home_card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #ffffff;
  box-shadow: 0px 0px 60px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  padding: 30px;
  /* box-shadow: 0px 50px 100px rgba(0, 0, 0, 0.1); */
}

.home_card:hover {
  box-shadow: 0px 50px 100px rgba(0, 0, 0, 0.1);
  border: 1px solid #46c35f;
}

.home_card img {
  width: 23px;
}

.home_card h4 {
  font-weight: 700;
  font-size: 20px;
  line-height: 40px;
  color: #2e2e2e;
  margin-top: 14px;
}

.home_card p {
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  margin-top: 8px;
}

.home_card_link {
  display: flex;
  align-items: center;
  font-weight: 900;
  font-size: 14px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #46c35f;
  margin-top: 14px;
  text-decoration: none;
  cursor: pointer;
}

.home_section_h5 {
  font-weight: 400;
  font-size: 14px;
  line-height: 40px;
  color: #46c35f;
  margin-bottom: 10px;
}

.home_section_h4 {
  font-weight: 700;
  font-size: 24px;
  line-height: 29px;
  color: #2e2e2e;
  margin-bottom: 26px;
}

.home_section_news_p {
  font-weight: 400;
  font-size: 16px;
  line-height: 160%;
  color: #84939b;
}

.home_section_news_date {
  font-weight: 500;
  font-size: 18px;
  line-height: 30px;
  color: #46c35f;
  margin-top: 10px;
}

.home_program_section_animation {
  width: 100%;
}

.home_program_section {
  padding: 1rem;
}

.home_program_section p {
  font-weight: 400;
  font-size: 16px;
  line-height: 34px;
  color: #2e2e2e;
  margin-bottom: 40px;
}

/* /////////////////////// ABOUT SECTION /////////////////////// */

.home_aboutUs_section {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  transform: translateX(0%);
  width: 100%;
  height: 400px;
  padding: 16px;
  background: #f4fffe;
  z-index: 2;
}

.home_aboutUS_overlap {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  transform: translateX(0%);
  height: 550px;
  padding: 16px;
  background: #f4fffe;
}

.home_aboutUs_section__img_wrapper {
  padding: 1rem;
  width: 100%;
  z-index: 20;
}

.home_aboutUs_section_wrapper_img {
  width: 100%;
  transform: translateY(40%);
  height: 100%;
}

.home_aboutUs_section p {
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  color: #343434;
  margin-bottom: 33px;
}

/* ////////////// PROGRAMME CARD SECTION ////////////////////////*/

.home_program_section_cards_animation {
  width: 100%;
}

.home_program_section_cards {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  gap: 20px 20px;
  padding: 1rem;
}

.home_program_section_card_wrapper {
  box-shadow: 0px 0px 60px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  height: 241px;
  background: #ffffff;
}

.home_program_section_card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 24px;
  border-radius: 10px;
  height: 100%;
}

.home_program_card {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 20px 20px;
}

.home_program_section_card:hover {
  background: #46c35f;
  border-bottom-right-radius: 70%;
  transition: ease-in-out 0.8s;
}

.home_program_section_card h4 {
  font-weight: 700;
  font-size: 14px;
  line-height: 40px;
  color: #000000;
}

.home_program_section_card_link {
  display: flex;
  align-items: center;
  font-weight: 900;
  font-size: 14px;
  letter-spacing: 0.05em;
  text-transform: uppercase;
  color: #46c35f;
  margin-top: 14px;
  text-decoration: none;
  cursor: pointer;
}

.home_program_section_card:hover h4,
.home_program_section_card:hover .arrow,
.home_program_section_card:hover p,
.home_program_section_card_link:hover {
  color: #ffffff;
}

.home_program_section_card p {
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  color: #2e2e2e;
}

.read_more:hover {
  color: #46c35f;
}

/*/////////////////////// IMPORTANT LINKS SECTIION ////////////////////////////// */
.home_link_section_wrapper {
  padding: 1rem;
}

.home_link_section_btn_wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* ///////////////////////// NEWS SECTION //////////////////// */

.home_news_section_cards {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  width: 100%;
}

.home_news_section {
  padding: 5rem 1rem 2rem 1rem;
  background: #f4fffe;
}

.home_news_section_heading_wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 4rem;
}

.home_news_section_card {
  width: 100%;
  cursor: pointer;
}

.home_news_cat {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #46c35f;
  font-weight: 400;
  font-size: 14px;
  line-height: 40px;
  margin: 10px 0px 10px;
}

/* ///////////// INFO SECTION ////////////////////////////////// */
.home_info_section {
  padding: 80px 16px;
}

.home_address,
.home_phone,
.home_email {
  margin-bottom: 1rem;
}

.home_address h4,
.home_phone h4,
.home_email h4 {
  font-weight: 700;
  font-size: 18px;
  line-height: 40px;
  display: flex;
  align-items: center;
  color: #2e2e2e;
  margin-bottom: 0.2rem;
}

.home_address img,
.home_phone img,
.home_email img {
  margin-right: 0.5rem;
}

.home_info_section_subscribe_wrapper {
  background: #ffffff;
  box-shadow: 0px 50px 150px rgba(0, 0, 0, 0.1);
  border-radius: 2px;
  height: auto;
  padding: 40px 59px;
  margin-top: 78px;
  width: 570px;
}

.home_info_section_subscribe_wrapper h4 {
  font-weight: 700;
  font-size: 20px;
  line-height: 30px;
  color: #2e2e2e;
  margin-bottom: 30px;
}

.home_info_section_subscribe_wrapper input {
  height: 48px;
  width: 100%;
  border: 1px solid #69747a;
  border-radius: 2px;
  margin-bottom: 30px;
  padding-left: 18px;
}

.home_checkbox {
  display: flex;
  align-items: flex-start;
  margin-bottom: 30px;
}

.home_checkbox input {
  width: 15px;
  height: 15px;
  border: 1px solid #69747a;
  border-radius: 2px;
  margin-bottom: 0px;
}

.home_checkbox p {
  font-weight: 400;
  font-size: 16px;
  line-height: 28px;
  color: #2e2e2e;
  margin-left: 8px;
  margin-top: -0.5rem;
  /* margin-bottom: 30px; */
}

.no-links,
.no-links ol {
  padding: 1rem;
}

.no-links li {
  list-style-type: decimal;
  font-weight: 400;
  font-size: 16px;
  line-height: 30px;
  color: #2e2e2e;
  margin-bottom: 10px;
  text-transform: capitalize;
}

/* ///////////////////////////// LARGE SCREEN /////////////// */

@media (min-width: 1700px) {
  .home_aboutUs_section,
  .home_aboutUS_overlap {
    height: 800px;
  }
}

@media (min-width: 1024px) {
  .header_wrapper {
    padding: 13px 92px 30px 92px;
  }

  .home_page_heading {
    font-weight: 900;
    font-size: 50px;
    line-height: 80px;
    width: 60%;
  }
  .call_us_wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 0rem 3rem;
  }

  .home_btn {
    width: 30%;
  }
  .home_card_wrapper {
    grid-template-columns: repeat(4, 1fr);
    margin-top: 80px;
    padding: 0px 92px;
  }
  .home_aboutUs_section_wrapper {
    position: relative;
    display: flex;
    align-items: center;
    margin-top: 3rem;
    z-index: 2;
  }
  .home_aboutUs_section_wrapper_img {
    width: 100%;
    height: 50%;
    transform: translateY(0%);
  }
  .home_aboutUs_section__img_wrapper {
    padding: 0rem;
    width: 70%;
    z-index: 20;
  }
  .home_aboutUs_section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 100%;
    padding: 0px 40px 0px 20px;
    align-items: flex-start;
    height: 550px;
  }
  .home_aboutUS_overlap {
    position: absolute;
    /* transform: translateX(-20%); */
    height: 550px;
    width: 15%;
    /* transform: translate(-50%, -50%); */
    right: 35%;
    left: 35%;
    z-index: 2;
  }

  /*//////////////// PROGRAMME //////////////////*/

  .home_program_section_wrapper {
    display: flex;
    padding: 150px 92px;
  }

  .home_program_section_animation {
    width: 50%;
  }

  .home_program_section {
    width: 100%;
  }

  .home_program_section_cards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px 20px;
    padding: 5rem 0rem;
    width: 100%;
    align-items: center;
  }
  .home_program_section_card_wrapper {
    box-shadow: 0px 0px 60px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    height: auto;
    background: #ffffff;
    width: 100%;
  }

  /*/////////////// LINKS ///////////////////  */

  .home_link_section_wrapper {
    padding: 100px 92px;
  }

  .home_link_section_btn_wrapper {
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
    gap: 20px;
  }

  .home_news_section,
  .home_info_section {
    padding: 0px 92px;
  }

  .home_news_section {
    padding: 3rem 92px;
  }

  .home_news_section_cards {
    grid-template-columns: repeat(3, 1fr);

    gap: 20px;
  }

  .home_info_section_address_wrapper_wrapper {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    gap: 150px;
    margin-bottom: 200px;
    padding: 0px 92px;
  }
  .home_info_section_address_wrapper {
    margin-top: 3rem;
  }
}

@media (max-width: 1020px) {
  .home_card_wrapper {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (min-width: 768px) {
  .home_btn {
    width: 30%;
  }
  .home_page_heading {
    font-weight: 900;
    font-size: 50px;
    line-height: 80px;
    width: 60%;
  }
}

@media (min-width: 800px) {
  .home_program_section_cards {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 20px 20px;
    padding: 5rem 0rem;
    width: 100%;
    align-items: center;
  }
}
@media (max-width: 500px) {
  .home_card_wrapper {
    grid-template-columns: repeat(1, 1fr);
  }
}
