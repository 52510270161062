.about_header_wrapper {
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.about_header_wrapper h1 {
  font-weight: 700;
  font-size: 50px;
  line-height: 60px;
  color: #ffffff;
  margin-bottom: 40px;
}

.about_header_wrapper p {
  font-weight: 400;
  font-size: 24px;
  line-height: 40px;
  text-align: center;
}

/* .about-us-setions-image{
  height: 480px;
  width: 100%;
  margin-bottom: 2rem;
} */

.container {
  margin: auto;
  max-width: 1170px;
}

.mobile_arrow_wrapper {
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  height: 60px;
  background: #ffffff;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.05);
  margin-top: 60px;
  padding: 26px 30px;
}

.about_section_images {
  width: 100%;
  margin-bottom: 2rem;
}

.mobile-arrow-btn {
  border: none;
  background: transparent;
}

.about_nti_wrapper {
  padding: 1rem;
  /* border: 1px solid red; */
}

.about_nti_wrapper p,
.about_achievement p,
.about_centers p,
.accordion_content_wrapper p {
  color: #2e2e2e;
  margin-bottom: 20px;
}

.about_nti_wrapper ul,
.about_governance ul,
.about_centers ul {
  padding: 1rem;
  width: 100%;
}

.about_governance img {
  width: 100%;
}

.about_nti_wrapper li,
.about_governance li,
.about_achievement_strategic_plan ul li,
.accordion_content_wrapper li {
  width: 100%;
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  color: #2e2e2e;
  margin-bottom: 20px;
}

.about_nti_wrapper li:before,
.about_governance li:before,
.about_achievement_strategic_plan ul li:before {
  content: "\2022";
  font-weight: bolder;
  display: inline-block;
  width: 1em;
  /* margin-right: 1rem; */
  color: #46c35f;
}

.about_nti_vision .about_nti_mission {
  background: #f8fffe;
  padding: 1rem 0rem;
  width: 100%;
}

.about_nti_vision,
.about_nti_mission,
.about_nti_introduction,
.about_nti_setup,
.about_nti_history {
  padding: 1rem 1rem;
  width: 100%;
}

#floating-menu {
  top: 100px;
  left: auto;
  right: 0;
  position: sticky;
}

.listItems {
  display: flex;
  border-top: 1px solid #eaeff3;
  border-bottom: 1px solid #eaeff3;
  cursor: pointer;
  padding: 1rem;
  margin-right: 3rem;
  color: black;
  text-decoration: none;
}

.listItems-active {
  color: rgba(79, 157, 107, 1);
}

.listItems:hover {
  color: rgba(79, 157, 107, 1);
}

.table-table-wrapper {
  width: 100%;
  overflow-y: hidden;
  padding: 1rem;
}

.table-table-wrapper p {
  width: 100%;
}
.table-table-wrapper table {
  width: 100% !important;
  border-collapse: collapse;
  padding: 1rem;
  margin: 26px 0px;
}

.table-table-wrapper tr:nth-child(1) {
  background: #46c35f;
  color: #fff;
  text-align: justify !important;
}

.table-table-wrapper th {
  padding: 20px 16px;
  text-align: justify !important;
}

.table-table-wrapper td {
  padding: 20px 16px;
  /* color: #2e2e2e; */
  text-align: justify !important;
}

.table-table-wrapper tbody:nth-of-type(odd) {
  background: #fbfdff;
}

.about_structure,
.about_functions,
.about_governance,
.about_achievement,
.about_centers {
  padding: 0rem 1rem;
  margin: 45px 0px 200px;
}

.about_structure ol,
.about_functions ol,
.about_achievement ol,
.about_centers ol {
  padding: 2rem;
}

.about_structure li,
.about_functions li,
.about_achievement ol li,
.about_centers li {
  list-style-type: decimal;
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
  color: #2e2e2e;
  margin-bottom: 10px;
  text-transform: capitalize;
}

.about_structure p {
  font-weight: 400;
  font-size: 18px;
  line-height: 30px;
}

.accordion_content_wrapper {
  background: #f5fffe;
  width: 100%;
}

.about_management {
  margin: 45px 0px 200px;
}

.about_management_img_wrapper {
  padding: 1rem 0rem;
  width: 100%;
}

.accordion_content_wrapper li:before {
  content: "◆";
  font-weight: bolder;
  display: inline-block;
  width: 1em;
  color: #46c35f;
}

.accordion_content_wrapper li {
  font-weight: 400;
  font-size: 16px;
  line-height: 40px;
  color: #2e2e2e;
}
